import React, { Component } from "react";
import "./detail.css";
import axios from "axios";
import Recommended from "./recommended";
export default class Moviedetail extends Component {
  state = {
    movie: null,
    youtubeID: null,
  };

  async componentDidMount() {
    let id = this.props.match.params.id;
    const moviedbReq = await axios.get(
      `https://api.themoviedb.org/3/movie/${id}?api_key=00511e16437f5d911eb6494f55abdca5`
    );

    this.setState({ movie: moviedbReq.data });

    axios
      .get(`https://www.googleapis.com/youtube/v3/search`, {
        params: {
          part: "snippet",
          q: this.state.movie.original_title + " official trailer",
          key: "AIzaSyAGBrn2bX3nUUTwnnx0dMCxIx3AY0CghhE",
          type: "video",
          maxResults: 1,
        },
      })
      .then((obj) => {
        this.setState({ youtubeID: obj.data.items[0].id.videoId });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.movie || !this.state.youtubeID) {
      return <h1>Loading data...</h1>;
    } else {
      let movie = this.state.movie;
      return (
        <section>
          <div
            className="container"
            style={{
              background: `url(https://image.tmdb.org/t/p/w500${movie.backdrop_path})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div id="content_container">
              <div id="youtubeTrailer">
                <iframe
                  className="video"
                  title="Youtube player"
                  sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                  src={`https://youtube.com/embed/${this.state.youtubeID}?autoplay=0`}
                ></iframe>
              </div>
              <div id="content">
                <h1>{movie.original_title}</h1>
                <p>{movie.tagline}</p>
                <h3>Rating:{movie.vote_average}</h3>
                <h3>Overview:</h3>
                <h4>{movie.overview}</h4>
                <h2>Budget:${movie.budget}</h2>
                <h2>Revenue:${movie.revenue}</h2>
                <h2>Release:{movie.release_date}</h2>
              </div>
            </div>
          </div>
          <Recommended id={movie.id} name={movie.original_title}></Recommended>
        </section>
      );
    }
  }
}
