import React from "react";
import ReactDOM from "react-dom";
import App from "./component/app";
import Recommendation from "./recommendation";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Moviedetail from "./component/moviedetail";
const Routing = () => {
  return (
    <Router>
 
      <Route exact path="/" component={App}></Route>
      <Route path="/movie/:id" component={Moviedetail}></Route>
      <Route path="/recommendation" component={Recommendation}></Route>
    </Router>
  );
};

ReactDOM.render(<Routing />, document.getElementById("root"));
