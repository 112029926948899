import React, { useEffect, useState } from "react";
import Single from "./component/singlemovie";
import "./recommendation.css";
import axios from "axios";

function Recommendation() {
  const [recList, setList] = useState([]);
  const [item, setItem] = useState();
  const [submit, setSubmit] = useState();

  useEffect(()=>{
    document.title = "Recommendation - MovieDB";
  })
  async function submitted(e) {
    e.preventDefault();
    setSubmit(true);
    const movie = await axios.get(
      `https://api.themoviedb.org/3/search/movie?append_to_response=videos&api_key=00511e16437f5d911eb6494f55abdca5`,
      {
        params: {
          query: item,
        },
      }
    );
    console.log(movie);
    const id = movie.data.results[0].id;
    const recommended = await axios.get(
      `https://api.themoviedb.org/3/movie/${id}/recommendations?api_key=00511e16437f5d911eb6494f55abdca5`
    );
    console.log(recommended.data.results);
    setList(recommended.data.results);
  }
  return (
    <div
      id="recommendationContainer"
      style={{ justifyContent: submit ? "start" : "center" }}
    >
      <h1>Movie Recommender</h1>
      <div id="recommendationSearchbar">
        <div>
          <form className="textbox" onSubmit={submitted}>
            <input
              value={item}
              type="textbox"
              placeholder="Enter your favourite movie"
              onChange={(e) => {
                setItem(e.target.value);
              }}
            />
            <i type="submit" class="search icon"></i>
          </form>
        </div>
      </div>
      <div id="list">
        {recList.map((movie) => {
          console.log(movie);
          return <Single movie={movie}></Single>;
        })}
      </div>
    </div>
  );
}

export default Recommendation;
