import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

export default class SearchBar extends Component {
  state = { item: "", debouncedValue: "" };
  id = 0;
  inputChanged = (txt) => {
    this.setState({ item: txt.target.value });

    clearTimeout(this.id);
    this.id = setTimeout(() => {
      this.submitted(this.state.item);
    }, 500);
  };

  submitted = (txt) => {
    console.log("Submiteed", txt);
    this.props.onsubmit(this.state.item);
  };

  render() {
    return (
      <div class="navContainer" id="searchdiv">
        <div id="logo">
          <span>Movie Finder</span>
        </div>
        <div id="searchbar" onSubmit={this.submitted}>
          <div className="textbox">
            <input
              value={this.state.item}
              type="textbox"
              placeholder="Search Your Movie.."
              onChange={this.inputChanged}
            />
            <i class="search icon"></i>
          </div>
        </div>
        <div>
          <Link to="/recommendation">
            <button id="recommendBtn">Recommend Me Movies</button>
          </Link>
        </div>
      </div>
    );
  }
}
