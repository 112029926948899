import React, { Component } from "react";
import SearchBar from "./searchbar";
import Searchmovie from "./searchmovie";
import Single from "./singlemovie";
import axios from "axios";

export default class App extends Component {
  state = { searched: false, list: [] };

  async componentDidMount() {
    document.title = "Home - MovieDB"
    const res = await axios.get(
      "https://api.themoviedb.org/3/discover/movie?api_key=00511e16437f5d911eb6494f55abdca5&sort_by=popularity.desc&language=en-US&page=1&vote_average.gte=5.5&with_genres=27"
    );
    const lst = res.data.results;
    const mov = lst.map((movie) => {
      return <Single movie={movie} />;
    });
    
    this.setState({ list: mov });
  }

  submitted = (txt) => {
    if (txt !== "") {
      axios
        .get(
          `https://api.themoviedb.org/3/search/movie?api_key=00511e16437f5d911eb6494f55abdca5`,
          {
            params: {
              query: txt,
            },
          }
        )
        .then((obj) => {
          const mov = obj.data.results.map((movie) => {
            if (!movie.adult) {
              return <Single movie={movie} />;
            }
          });
          this.setState({ list: mov, searched: true });
        })
        .catch(() => {
          console.log("Not reached");
        });
    }
  };

  render() {
    return (
      <div>
        <SearchBar className="searchar" onsubmit={this.submitted} />
        {!this.state.searched ? (
          <h1 style={{ textAlign: "center" }}>New & Trending🔥</h1>
        ) : (
          ""
        )}
        <Searchmovie className="moviecontainer" title={this.state.list} />
      </div>
    );
  }
}
