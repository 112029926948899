import axios from "axios";
import React, { useEffect, useState } from "react";
import Single from "./singlemovie";

function Recommended({ id, name }) {
  const [recList, setList] = useState();

  const getRecomendation = async () => {
    const recommended = await axios.get(
      `https://api.themoviedb.org/3/movie/${id}/recommendations?api_key=00511e16437f5d911eb6494f55abdca5`
    );
    console.log(recommended.data.results);
    setList(recommended.data.results);
  };
  useEffect(() => {
    
    getRecomendation();
  }, []);
  if (!recList) {
    return <h1>Loading.</h1>;
  }
  return (
    <div>
      <h1 style={{ textAlign: "center", marginTop: "5rem" }}>
        Other recommended songs like {name}
      </h1>
      <div id="list">
        {recList.map((movie) => {
          console.log(movie);
          return <Single movie={movie}></Single>;
        })}
      </div>
    </div>
  );
}

export default Recommended;
